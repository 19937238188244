#react-modal-image-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

#react-modal-image-container #react-modal-image-img {
  margin-bottom: 10px;
  position: initial;
}

#react-modal-image-container span {
  color: white;
  text-align: center;
}